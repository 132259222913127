import React from "react";
import { Link } from "gatsby";
import SEO from "../components/SEO";
import Layout from "../components/layout";
import Banner from "../components/Banner";
import Explainer from "../components/Explainer";

class HomeIndex extends React.Component {
  render() {
    const schema = {
        "@context": "https://schema.org",
        "@graph": [
          {
            "@type": "Organization",
            "@id": "https://brandinavian.com/#organization",
            name: "Brandinavian",
            url: "https://brandinavian.com/",
            sameAs: [
              "https://www.facebook.com/brandinavian/",
              "https://www.instagram.com/brandinavian/",
              "https://www.linkedin.com/company/brandinavian/",
              "https://twitter.com/brandinavian",
            ],
            logo: {
              "@type": "ImageObject",
              "@id": "https://brandinavian.com/#logo",
              inLanguage: "en-US",
              url:
              "https://cdn.brandinavian.com/wp-content/uploads/2020/09/brandinavian-logo.png",
              width: 1112,
              height: 116,
              caption: "Brandinavian | Modern Web Development",
            },
            image: { "@id": "https://brandinavian.com/#logo" },
          },
          {
            "@type": "WebSite",
            "@id": "https://brandinavian.com/#website",
            url: "https://brandinavian.com/",
            name: "Brandinavian",
            description: "USA Web Developer",
            publisher: { "@id": "https://brandinavian.com/#organization" },
            inLanguage: "en-US",
          },
          {
            "@type": "WebPage",
            "@id": "https://brandinavian.com/#webpage",
            url: "https://brandinavian.com",
            name: "Brandinavian | Modern Web Development",
            isPartOf: { "@id": "https://brandinavian.com/#website" },
            description:"Take your website to the next level with React, Gatsby and WordPress. Get started with Brandinavian today!",
            breadcrumb: {
              "@id":
                "https://brandinavian.com/#breadcrumb",
            },
            inLanguage: "en-US",
            potentialAction: [
              {
                "@type": "ReadAction",
                target: ["https://brandinavian.com"],
              },
            ],
          },
          {
            "@type": "BreadcrumbList",
            "@id":
              "https://brandinavian.com/#breadcrumb",
            itemListElement: [
              {
                "@type": "ListItem",
                position: 1,
                item: {
                  "@type": "WebPage",
                  "@id": "https://brandinavian.com",
                  url: "https://brandinavian.com",
                  name: "Web developer",
                },
              },
            ],
          },
        ],
      };
    return (
      <Layout altLogo={`black`}>
        <SEO lang="en-US" schemaMarkup={schema} />

        <Banner />

        <div id="main">
          <section id="one" className="tiles">
            <article
              style={{ backgroundImage: `url()`, backgroundColor: `#27292b` }}
            >
              <header className="major">
                <h3>Web development</h3>
                <p>Scalable, modern applications</p>
              </header>
              <Link to="/web-development" className="link primary"></Link>
            </article>
            <article
              style={{ backgroundImage: `url()`, backgroundColor: `#ACB5AC` }}
            >
              <header className="major">
                <h3>Design</h3>
                <p>Branding, identities & graphics</p>
              </header>
              <Link to="/design" className="link primary"></Link>
            </article>
            <article
              style={{ backgroundImage: `url()`, backgroundColor: `#C9A3A4` }}
            >
              <header className="major">
                <h3>SEO</h3>
                <p>Organic traffic generation</p>
              </header>
              <Link to="/seo" className="link primary"></Link>
            </article>
            <article
              style={{ backgroundImage: `url()`, backgroundColor: `#D5B795` }}
            >
              <header className="major">
                <h3>CRO</h3>
                <p>User retention</p>
              </header>
              <Link to="/cro" className="link primary"></Link>
            </article>
            <article
              style={{ backgroundImage: `url()`, backgroundColor: `#8ea9e8` }}
            >
              <header className="major">
                <h3>UX Engineering</h3>
                <p>Design systems and more</p>
              </header>
              <Link to="/ux-engineering" className="link primary"></Link>
            </article>
            <article
              style={{ backgroundImage: `url()`, backgroundColor: `#27292b` }}
            >
              <header className="major">
                <h3>Get started today</h3>
                <p>Let's build something great</p>
              </header>
              <Link to="/contact" className="link primary"></Link>
            </article>
          </section>
          <Explainer />
        </div>
      </Layout>
    );
  }
}

export default HomeIndex;
