import React from "react";
import { Link } from "gatsby";
import { graphql, StaticQuery } from "gatsby";
import Img from "gatsby-image";

class Explainer extends React.Component {

  render() {
    return (
      <StaticQuery
        query={graphql`
  query {
    imageOne: file(relativePath: { eq: "madsv.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
        } 
      }
    }
}
`}
        render={data => (
            <section id="two">
    <div className="inner special explainer">
    <div className="about-box">
    <div className="about-image">
    <Img fluid={data.imageOne.childImageSharp.fluid} alt="" />
    </div>
    <div className="about-explainer">
        <header className="major special">
            <h2>A wide range of competencies</h2>
        </header>
        <p className="displayText">As a digital native, I have always had a computer between my hands. Originally I was educated in the technical areas of my field, but later decided to expand on my abilities and methodology, with a Master's degree in Media Science. I now build best-in-class, scalable web applications, that convert and communicate well. I use state-of-the-art web technologies, such as <b>React</b>, <b>GatsbyJS</b>, <b>GraphQL</b>, <b>Node</b> and <b>Netlify</b>. Graphical work, branding and concept development are also strong suites of mine.</p>
        <ul className="actions lowkey">
            <li><Link to="/about" className="button next">More about me</Link></li>
        </ul>
        </div>
        </div>
    </div>
</section>
        )}
      />
    );
  }
}

export default Explainer;