import React from 'react'
import * as THREE from 'three'
import FOG from 'vanta/dist/vanta.fog.min'

class Banner extends React.Component {
    constructor() {
      super()
      this.vantaRef = React.createRef()
      this.zoomLevel = 1.0;
      if (typeof window !== `undefined`) {
      const mediaQuery = window.matchMedia('(max-width: 1500px)')
      if (mediaQuery.matches) {
        this.zoomLevel = 0.5;
      } else {
        this.zoomLevel = 1.0;
      }
    }
    }
    componentDidMount() {
      this.vantaEffect = FOG({
        el: this.vantaRef.current,
        THREE: THREE,
        highlightColor: 0xff00f0,
        midtoneColor: 0xb3ff,
        zoom: this.zoomLevel
      })
    }
    componentWillUnmount() {
      if (this.vantaEffect) this.vantaEffect.destroy()
    }
    render() {
      return(
            <section id="banner" className="major" ref={this.vantaRef}>
        <div className="inner">
            <header className="major">
                <h1>Hi, my name is Mads</h1>
            </header>
            <div className="content">
                <p>I'm a Full Stack Engineer and Designer<br />
                  with over 8 years of experience.</p>
                <ul className="actions">
                    <li><a href="#one" className="button next scrolly">My Expertises</a></li>
                </ul>
            </div>
        </div>
    </section>)
    }
  }

export default Banner
